export const environment = {
  production: true,
  isDemo: false,
  firebase: {
    apiKey: 'AIzaSyCHA5ItKjjE5j6s4CgoAcjvCn-pI92Fkd8',
    authDomain: 'enroll-here.firebaseapp.com',
    projectId: 'enroll-here',
    storageBucket: 'enroll-here.appspot.com',
    messagingSenderId: '68638839047',
    appId: '1:68638839047:web:907cfb3b4f154f42fd35a2',
    measurementId: 'G-M7E05VZ5CS',
    appCheckRecaptchaSiteKey: '6LdmR8IpAAAAAH511RIimBcXy-7_TNZUHuYykBzf',
  },
  googleMapsApiKey: 'AIzaSyBR9wal5Ti1JLJvOdx3t4vAgYFjW_EnW3g',
  devVersion: '{BUILD_VERSION}',
  enrollhereBaseUrl: 'https://app.enrollhere.com',
  loginUrl: 'https://app.enrollhere.com/login?redirect=commissions',
  elasticHttp: 'https://enrollhere.es.us-east1.gcp.elastic-cloud.com/',
  elasticPrefix: 'enrollhere-commissions_',
  elasticPrefixBase: 'enrollhere_',
  sentry: {
    dsn: 'https://8e7611703c32db7391dfc6a775b3bdb8@o4506558808064000.ingest.us.sentry.io/4506900543766528',
    tracePropagationTargets: [
      /^https:\/\/enroll-here-dev-commissions\.web\.app/,
    ],
    environment: 'production',
  },
};
